import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputBaseComponent } from '../form-input-base.component';

@Component({
  selector: 'app-filter-text-box',
  templateUrl: './filter-text-box.component.html',
  styleUrls: ['./filter-text-box.component.scss']
})
export class FilterTextBoxComponent extends FormInputBaseComponent implements OnInit {

  protected readonly currentDir: string;

  private _searchValue: string;
  public get searchValue(): string {
    return this._searchValue;
  }

  @Input('searchValue')
  public set searchValue(v: string) {
    this._searchValue = v;
    this.searchValueChange.emit(v);
  }

  @Output() searchValueChange = new EventEmitter<string>();
  @Output() onSearch = new EventEmitter<boolean>();


  constructor() {
    super();
    this.currentDir = document.getElementsByTagName('html')[0].getAttribute('dir') ?? 'rtl';
  }

  ngOnInit() {
  }

  protected checkKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSearch.emit(true);
    }
  }
}
