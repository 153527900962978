import { ValidationErrors } from "@angular/forms";

export const getValidatorErrorMessage = (validatorName: string, validatorErrors?: ValidationErrors): string | undefined => {

  const langEn = localStorage.getItem('lang') === 'en';
  const args = messages.get(validatorName)?.validatorErrorsKey?.map(name => validatorErrors?.[name]);

  const messageMap = messages.get(validatorName);
  const message = langEn ? messageMap?.message : messageMap?.messageAr;

  return (args) ? stringFormat(message, ...args) : message;
}

const messages = new Map<string, { messageAr: string, message: string, validatorErrorsKey?: string[] }>([
  ['required', { messageAr: 'هذا الحقل مطلوب', message: 'Required Field' }],
  ['min', { messageAr: 'هذا الحقل مطلوب', message: 'Required Field' }],
  ['max', { messageAr: 'يجب ألا يتجاوز الحقل القيمة {0}', message: 'The field must not exced {0}', validatorErrorsKey: ['max'] }],
  ['minlength', { messageAr: 'عدد خانات الحقل يجب أن تكون {0} على الأقل ', message: 'The field must have at least {0} characters', validatorErrorsKey: ['requiredLength'] }],
  ['maxlength', { messageAr: 'عدد خانات الحقل يجب أن تكون {0} على الأكثر', message: 'The field must have at most {0} characters', validatorErrorsKey: ['requiredLength'] }],
  ['pattern', { messageAr: 'صيغة المدخل غير صحيحة', message: 'The value format is invalid' }],
  ['email', { messageAr: 'صيغة البريد الإلكتروني غير صحيحة', message: 'Invalid email address' }],
  ['confirmPassword', { messageAr: 'تأكيد كلمة المرور لا تتطابق', message: 'The password confirmation does not match' }],
  ['arrayEmpty', { messageAr: 'يجب اضافة عنصر واحد على الأقل', message: 'At least one item must be added' }],
  ['saudiNumberInvalid', { messageAr: 'صيغة رقم الجوال غير صحيحة', message: 'Invalid phone number format' }],
]);

function stringFormat(template: string | undefined, ...args: any[]) {
  if (template) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined'
        ? args[index]
        : match;
    });
  }
  return undefined;
}


