@if(mode == 'reactive'){
<div class="filter-text-container d-flex" [dir]="currentDir" [formGroup]="formGroup">
  <input type="text" class="form-control rounded-5" [placeholder]="placeholder" id="{{controlName}}_Id"
    [name]="controlName" [formControlName]="controlName" aria-label="Example text with button addon"
    aria-describedby="button-addon1" (keypress)="checkKeyPress($event)">
  <button class="btn" [dir]="currentDir" type="button" id="button-addon1" (click)="onSearch.emit(true)">
    <i class="bi bi-search"></i>
  </button>
</div>
} @else {
<div class="filter-text-container d-flex" [dir]="currentDir">
  <input type="text" class="form-control rounded-5" [placeholder]="placeholder" id="{{controlName}}_Id"
    [name]="controlName" aria-label="Example text with button addon" aria-describedby="button-addon1"
    [(ngModel)]="searchValue" (keypress)="checkKeyPress($event)">
  <button class="btn" [dir]="currentDir" type="button" id="button-addon1" (click)="onSearch.emit(true)">
    <i class="bi bi-search"></i>
  </button>
</div>
}