import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'htmlParser',
  standalone: true
})
export class HtmlParserPipe implements PipeTransform {

  /**
   *
   */
  constructor(private san: DomSanitizer) {


  }

  transform(value: string): SafeHtml {
    return this.san.bypassSecurityTrustHtml(value)
  }

}
