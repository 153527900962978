import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'form-input-label',
  styles: [`
 label.required::after {
   content: '*';
   color: red;
 }
 `],
  templateUrl: './input-label.component.html',
})
export class InputLabelComponent {
  @Input('controlName') controlName!: string;
  @Input('formGroup') formGroup!: FormGroup
  @Input('label') label!: string;
  @Input('labelClass') labelClass!: string | null | undefined;
  @Input('text-wrap') textWrap: boolean = true;


  get isRequired() {
    if (!this.formGroup || !this.controlName) {
      return undefined;
    }

    return this.formGroup.controls[this.controlName]?.hasValidator(Validators.required);

  }
}
