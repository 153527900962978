import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { getDirection } from "../../helpers/helpers";

@Component({
  selector: 'FormInputBaseComponent-Shared',
  template: ''
})
export abstract class FormInputBaseComponent {

  @Input('mode') mode: 'template' | 'reactive' = 'reactive'
  @Input('isRequired') isTemplateRequired: boolean = false;

  @Input('formGroup') formGroup!: FormGroup
  @Input('controlName') controlName!: string;
  @Input('isSubmitted') isSubmitted!: boolean;

  @Input('label') label!: string;
  @Input('labelClass') labelClass!: string;
  @Input('helpText') helpText!: string;
  @Input('placeholder') placeholder!: string;
  @Input('styleClass') styleClass!: string;
  @Input('formGroupClass') formGroupClass!: string;
  @Input('autocomplete') autocomplete!: string;

  // ng-model mode
  @Input() inputValue?: any | undefined;
  @Output() inputValueChange: EventEmitter<any> = new EventEmitter<any>();


 protected readonly max: number = 2147483647 //maximum int32 number;  


  get dir() { return getDirection() }
  protected get isAr() { return localStorage.getItem('lang') == 'ar' };

  protected onInputChange(value: any) {
    this.inputValue = value;
    this.inputValueChange.emit(value);
  }

  reset() {
    this.inputValue = undefined;
    this.inputValueChange.emit(this.inputValue);
  }


  constructor() {

  }


  get isInvalid(): boolean | undefined {
    if (!this.formGroup || !this.controlName) {
      return undefined;
    }

    return this.formGroup.controls[this.controlName]?.invalid && this.isSubmitted;
  }

}
