import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getValidatorErrorMessage } from './validators-utils';

@Component({
  selector: 'app-error-messages',
  template: `@if(errorMessage !== null){<span class="invalid-feedback d-block" > {{errorMessage}} </span> }`,
  styles: []
})
export class ErrorMessagesComponent {
  @Input()
  controlName!: string;

  @Input()
  form!: FormGroup;

  @Input()
  isSubmitted!: boolean;


  get errorMessage() {
    for (const validatorName in this.form.get(this.controlName)?.errors) {
      const formControl = this.form.get(this.controlName);
      if ((formControl?.invalid && this.isSubmitted)) {
        const formControl = this.form.get(this.controlName);
        return getValidatorErrorMessage(validatorName, formControl?.errors?.[validatorName]);
      }
    }
    return null;
  }

}
